<template>
<v-row class="tasks">
    <v-col cols="8" class="tasks-calendar" v-if="loading">
        <v-skeleton-loader
            type="table-heading, table-thead, table-tbody, table-tbody"
            height="740"
        />
    </v-col>
    <v-col v-else cols="8" class="tasks-calendar">
        <v-sheet height="64" class="mb-6">
            <v-toolbar
                    flat
                    class="tasks__toolbar"
            >
                <v-btn
                        outlined
                        class="evi-button-white mr-4"
                        @click="setToday"
                >
                    Сегодня
                </v-btn>
                <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                        class="tasks__arrow"
                >
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                        class="tasks__arrow"
                >
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-select
                    class="evi-select evi-select-calendar-type mr-11"
                    color="#44D370"
                    v-model="type"
                    :items="typeToLabel"
                    item-text="name"
                    item-value="value"
                    item-color="#44D370"
                >
                    <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                    </template>
                </v-select>
                <v-btn class="evi-button-green evi-button-green--fill" @click="createTaskModal = true" v-if="hasRightInclude('tasks.')">
                    <v-icon class="mr-1">mdi-plus</v-icon>
                    Создать задачу
                </v-btn>
            </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
            <v-calendar
                ref="calendar"
                :weekdays="weekdays"
                v-model="focus"
                :events="events"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                class="evi-calendar"
                :event-overlap-mode="mode"
            />
        </v-sheet>
    </v-col>
    <v-col v-if="loading" class="tasks-list">
        <v-skeleton-loader
            type="card-heading, divider, list-item, list-item, list-item, list-item"
            height="740"
        />
    </v-col>
    <v-col v-else class="tasks-list">
        <h3>Список задач</h3>
        <v-divider />
        <div class="tasks-list__side">
            <span>{{new Date() | moment("D MMMM")}}</span>
            <div v-if="todayEventsList.length" class="tasks-list__items mt-4">
                <div class="task" v-for="task in todayEventsList" :key="task.id" @click="openTask(task.id)">
                    <div class="task__divider" :style="{backgroundColor: `${task.color}`}"></div>
                    <div class="task__name">{{task.name}}</div>
                </div>
            </div>
            <div v-else class="tasks-list__zero">Активных задач нет</div>
        </div>
    </v-col>
    <v-dialog
            v-model="createTaskModal"
            max-width="718px"
    >
        <CreateTask @closeAfterCreate="closeCreateTask" @close="createTaskModal = false"/>
    </v-dialog>
</v-row>
</template>

<script>
    import CreateTask from "../../components/tasks/CreateTask";
    import {mapActions, mapState} from "vuex";
    import {hasRightInclude, hasRight} from '@/utils/access/hasRight';

    export default {
        name: 'Tasks',
        components: {CreateTask},
        data: () => ({
            focus: '',
            type: 'month',
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            weekdays: [1,2,3,4,5,6,0],
            createTaskModal: false,
            colors: ['#FFBF44', '#FF8D63', '#2DCBAE', '#F89CC9', '#695DE7'],
            todayEventsList: [],
            typeToLabel: [
                {
                    name: 'Месяц',
                    value: "month"
                },
                {
                    name: 'День',
                    value: "day"
                },
            ],
            mode: 'column',
        }),
        computed: {
            ...mapState("tasks", ["tasks"]),
            ...mapState("common", ["loading"]),
        },
        methods: {
            ...mapActions({
                getTasks: 'tasks/getTasks',
                createTask: 'tasks/createTask',
            }),
            hasRightInclude: hasRightInclude,
            hasRight: hasRight,
            viewDay ({ date }) {
                this.focus = date;
                this.type = 'day';
            },
            setToday () {
                this.focus = '';
            },
            prev () {
                this.$refs.calendar.prev();
            },
            next () {
                this.$refs.calendar.next();
            },
            showEvent ({ nativeEvent, event }) {
                const open = () => {
                    this.selectedEvent = event;
                    this.selectedElement = nativeEvent.target;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
                };

                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => open()));
                } else {
                    open();
                }

                nativeEvent.stopPropagation();
                this.openTask(event.id);
            },
            closeCreateTask () {
                this.getEvents();
                this.createTaskModal = false;
            },
            getEvents () {
                this.getTasks().then(() => {
                    let events = [];

                    this.tasks.forEach(i => {

                        events.push({
                            name: i.marked_as_done_at ? `${i.name} (Завершено)` : i.name,
                            id: i.id,
                            start: i.end_date_from,
                            end: i.end_date_to,
                            color: this.colors[this.rnd(0, this.colors.length - 1)],
                            timed: true,
                            marked_as_done_at: i.marked_as_done_at,
                        })
                    });

                    this.events = events;

                    this.setTodayEvents();
                });
            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            setTodayEvents () {
                let events = [];
                const now = this.$moment(new Date).format('YYYY-MM-DD');

                this.events.forEach(item => {
                    const first = this.$moment(item.start).format('YYYY-MM-DD');
                    const last = this.$moment(item.end).format('YYYY-MM-DD');

                    if ( first <= now && now <= last && !item.marked_as_done_at) {
                        events.push(item);
                    }
                });

                this.todayEventsList = events;
            },
            openTask(id) {
                this.$router.push({path: `/tasks/${id}`});
            }
        },
        mounted () {
            this.getEvents();
        },
    }
</script>

<style lang="scss" scoped>
.tasks {
    flex-wrap: nowrap !important;
    &-calendar {
        background: #FFFFFF;
        //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 26px 0;
        margin-right: 19px;
    }
    &-list {
        background: #FFFFFF;
        //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 17px 0;

        h3 {
            margin-left: 27px;
            margin-top: 15px;
            margin-bottom: 25px;
            color: $evi-headline-color;
            font-weight: bold;
            font-size: 20px;
        }

        hr {
            background: #F7F7F7 !important;
            height: 2px;
            max-height: 2px;
        }

        &__side {

            span {
                font-weight: normal;
                font-size: 14px;
                color: $evi-grey-color;
                padding: 27px 27px 0;
                display: block;
            }
        }

        &__items {
            overflow-y: scroll;
            padding: 0 27px 27px;
            max-height: 613px;
        }

        &__zero {
            margin-top: 16px;
            font-weight: 600;
            font-size: 14px;
            color: #5C5C5C;
            padding: 27px 27px 0;
        }

        .task {
            background: #FFFFFF;
            border: 1px solid #F7F7F7;
            box-sizing: border-box;
            box-shadow: 0 4px 13px rgba(0, 0, 0, 0.13);
            border-radius: 10px;
            margin-bottom: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 23px;

            &__divider {
                min-width: 3px;
                min-height: 29px;
                display: block;
                border-radius: 3px;
                margin-left: 17px;
                margin-right: 21px;
                background-color: #5C5C5C;
            }

            &__name {
                font-weight: 600;
                max-width: 100%;
                word-break: break-word;
                max-height: 100px;
                overflow: hidden;
                display: inline;
                text-overflow: ellipsis;
            }

            &__time {
                font-size: 12px !important;
                max-width: 60px;
            }

            * {
                font-size: 14px;
                color: #5C5C5C;
            }
        }
    }

    .evi-button-white {
        width: 122px;
        height: 28px;
    }
}


.v-application .primary--text {
  color: #44D370 !important;
  caret-color: #44D370 !important;
}
</style>
