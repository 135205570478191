<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Создание задачи</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="taskName"
              placeholder="Введите название задачи"
              :rules="taskNameRules"
              maxLength="50"
              counter="50"
          >
            <template v-slot:label>
              Название задачи
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-textarea
              no-resize
              class="evi-textarea mb-5"
              color="#44D370"
              v-model="description"
              :rules="descriptionRules"
          >
            <template v-slot:label>
              Описание задачи
              <span class="important">*</span>
            </template>

          </v-textarea>
          <div class="mb-5">
            <v-autocomplete
                v-if="hasRight('tasks.all')"
                class="evi-autocomplete mb-5"
                color="#44D370"
                :items="localUsers"
                v-model="executor"
                item-text="name"
                item-value="id"
                :rules="executorRules"
                :loading="userLoading"
                @focus="loadUsers"
                :search-input.sync="userSearch"
                @input.native="searchUser(userSearch)"
                clearable
                cache-items
                item-color="green"
            >
              <template v-slot:label>
                Исполнитель
                <span class="important">*</span>
              </template>
              <template v-slot:append-item>
                <div v-intersect="userIntersect"/>
              </template>
              <template v-slot:item="{item}">
                <v-avatar
                    color="#FFFFFF"
                    size="30"
                    class="user-page__avatar mr-3"
                >
                  <img
                      :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
                <div class="v-list-item__title">{{ item.name }}</div>
              </template>
              <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
              </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="hasRight('tasks.all')"
                class="evi-autocomplete"
                color="#44D370"
                :items="localUsers"
                v-model="curator"
                item-text="name"
                item-value="id"
                :rules="curatorRules"
                :loading="userLoading"
                @focus="loadUsers"
                :search-input.sync="curatorSearch"
                @input.native="searchUser(curatorSearch)"
                clearable
                cache-items
                item-color="green"
            >
              <template v-slot:label>
                Куратор
                <span class="important">*</span>
              </template>
              <template v-slot:append-item>
                <div v-intersect="userIntersect"/>
              </template>
              <template v-slot:item="{item}">
                <v-avatar
                    color="#FFFFFF"
                    size="30"
                    class="user-page__avatar mr-3"
                >
                  <img
                      :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
                <div class="v-list-item__title">{{ item.name }}</div>
              </template>
              <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
              </template>
            </v-autocomplete>
            <v-autocomplete
                class="evi-autocomplete"
                color="#44D370"
                :items="localClients"
                v-model="client"
                item-text="name"
                item-value="id"
                :loading="clientsLoading"
                @focus="loadClients"
                :search-input.sync="clientSearch"
                @input.native="searchClient(clientSearch)"
                clearable
                item-color="green"
            >
              <template v-slot:label>
                Связать с клиентом
              </template>
              <template v-slot:append-item>
                <div v-intersect="clientIntersect"/>
              </template>
              <template v-slot:item="{item}">
                <v-avatar
                    color="#FFFFFF"
                    size="30"
                    class="user-page__avatar mr-3"
                >
                  <img
                      :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
                <div class="v-list-item__title">{{ item.name }}</div>
              </template>
              <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
              </template>
            </v-autocomplete>
          </div>
          <div class="d-flex mb-5">
            <div class="evi-datetime mr-9">
              <v-datetime-picker
                  dateFormat="dd.MM.yyyy"
                  clearText="Отмена"
                  ok-text="Принять"
                  locale="ru-ru"
                  label="От"
                  v-model="endDateFrom"
                  :textFieldProps="fromTextFieldProps"
                  :datePickerProps="datePickerProps"
                  :timePickerProps="timePickerProps"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </div>
            <div class="evi-datetime">
              <v-datetime-picker
                  dateFormat="dd.MM.yyyy"
                  clearText="Отмена"
                  ok-text="Принять"
                  locale="ru-ru"
                  label="До"
                  v-model="endDateTo"
                  :textFieldProps="toTextFieldProps"
                  :datePickerProps="datePickerProps"
                  :timePickerProps="timePickerProps"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </div>
          </div>
          <v-file-input
              chips
              multiple
              class="evi-text-field"
              label="Прикрепить файлы"
              color="#44D370"
              v-model="files"
          ></v-file-input>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать задачу
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {hasRight} from '@/utils/access/hasRight';
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {ClientAutocompleteRequestsMixin} from "@/mixins/autocomplete/ClientAutocompleteRequestsMixin";

export default {
  name: "CreateTask",
  mixins: [UserAutocompleteRequestsMixin, ClientAutocompleteRequestsMixin],
  props: {
    currentClient: {
      type: Object,
    },
  },
  data: () => ({
    valid: true,
    taskName: '',
    description: '',
    executor: null,
    curator: null,
    endDateFrom: '',
    endDateTo: '',
    taskNameRules: [(v) => !!v || 'Введите названия задачи'],
    executorRules: [(v) => !!v || 'Выберите исполнителя'],
    curatorRules: [(v) => !!v || 'Выберите куратора'],
    descriptionRules: [(v) => !!v || 'Опишите задачу'],
    endDateFromMenu: false,
    endDateToMenu: false,
    endFromKey: 1,
    files: [],
    client: null,
    fromTextFieldProps: {
      color: '#44D370',
      rules: [(v) => !!v || 'Укажите начальный срок задачи'],
    },
    toTextFieldProps: {
      color: '#44D370',
      rules: [(v) => !!v || 'Укажите конечный срок задачи'],
    },
    datePickerProps: {
      color: '#44D370',
      firstDayOfWeek: 1,
    },
    timePickerProps: {
      color: '#44D370',
      format: "24hr",
    },
  }),
  computed: {
    ...mapState("user", ["profile"]),
    localUsers() {
      let array = [];
      array.push(this.profile);
      this.users.forEach(i => {
        if (i.id !== this.profile.id) {
          array.push(i);
        }
      });
      return array;
    },
    endDateFromFormated() {
      return this.$moment(this.endDateFrom).format('YYYY-MM-DD HH:mm:ss');
    },
    endDateToFormated() {
      return this.$moment(this.endDateTo).format('YYYY-MM-DD HH:mm:ss');
    },
    localClients() {
      if (this.clients.length) {
        return this.clients;
      } else if (this.currentClient) {
        return [this.currentClient];
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      createTask: 'tasks/createTask',
    }),
    hasRight: hasRight,
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        //Если без файлов
        if (this.files.length === 0) {
          let payload = {
            name: this.taskName,
            description: this.description,
            executor_id: this.executor,
            curator_id: this.curator,
            end_date_from: this.endDateFromFormated,
            end_date_to: this.endDateToFormated,
            attachments: this.files,
          };

          if (this.client)
            payload.associated_client_id = this.client;

          this.createTask(payload).then(() => {
            this.closeDialogAfterCreate();
          });
        } else {
          //Если с файлами
          let formData = new FormData();

          this.files.forEach((item) => {
            formData.append('attachments[]', item);
          });

          formData.append('name', this.taskName);
          formData.append('description', this.description);
          formData.append('executor_id', this.executor);
          formData.append('curator_id', this.curator);
          formData.append('end_date_from', this.$moment(this.endDateFrom).format('YYYY-MM-DD HH:mm:ss'));
          formData.append('end_date_to', this.$moment(this.endDateTo).format('YYYY-MM-DD HH:mm:ss'));

          if (this.client)
            formData.append('associated_client_id', this.client);

          this.createTask(formData).then(() => {
            this.closeDialogAfterCreate();
          });
        }
      }
    },
    closeDialogAfterCreate() {
      this.$emit('closeAfterCreate');
      this.taskName = '';
      this.description = '';
      this.executor = this.profile.id;
      this.curator = this.profile.id;
      this.endDateFrom = '';
      this.endDateTo = '';
      this.files = [];
      this.$refs.form.resetValidation();
    },
    closeDialog() {
      this.$emit('close');
      this.taskName = '';
      this.description = '';
      this.executor = this.profile.id;
      this.curator = this.profile.id;
      this.endDateFrom = '';
      this.endDateTo = '';
      this.files = [];
      this.$refs.form.resetValidation();

      if (this.currentClient) {
        this.client = this.currentClient.id;
      }
    },
    setDefaultEndDateFrom() {
      this.endDateFrom = new Date;
    },
    setDefaultEndDateTo() {
      this.endDateTo = new Date(this.endDateFrom.getTime() + 600000);
    },
  },
  mounted() {
    this.curator = this.profile.id;
    this.executor = this.profile.id;
    if (!this.hasRight('tasks.all')) this.executor = this.profile.id;
    this.setDefaultEndDateFrom();
    this.setDefaultEndDateTo();

    if (this.currentClient) {
      this.client = this.currentClient.id;
    }
  },
  watch: {
    endDateFrom(val) {
      if (!val) {
        this.setDefaultEndDateFrom();
      }
    },
    endDateTo(val) {
      if (!val) {
        this.setDefaultEndDateTo();
      }
    }
  }
}
</script>

<style lang="scss">
.modal-create__form {
  padding: 0 115px !important;
}
</style>
